import React from 'react'
import { graphql } from 'gatsby'

import Insights from '../components/marketplace/insights'
import MarketplaceLayout from '../components/marketplace/MarketplaceLayout'

export default class ReportTagPage extends React.Component {
  render() {
    const { data } = this.props
    return (
      <MarketplaceLayout active="insights">
        <Insights reports={data.allReport.edges.map(({ node }) => node)} individualPage />
      </MarketplaceLayout>
    )
  }
}

export const reportTagPageQuery = graphql`
  query ReportTagPage($tag: String) {
    allReport(filter: { tags: { in: [$tag] } }) {
      edges {
        node {
          title
          price
          previewImage
          details
          tags
          _id
        }
      }
    }
  }
`
